import React from "react";
import GatsbyLink from "../../GatsbyLink";
import { ImagePass } from "../../Image/ImagePass";
import "./ProjectList.scss";

export const ProjectList = ({
  posts,
  pageContext,
  categories,
  pathPrefix,
  siteMetadata,
}) => {
  return (
    <section className="project-list">
      <div className="project-list-wrap grid-gallery">
        {Object.keys(posts).length > 0 &&
          posts?.map((post, index) => {
            const postContent = post?.node;
            // console.log(postContent);
            return (
              !postContent.slug.includes("gatsby") && (
                <GatsbyLink
                  to={post?.node?.path}
                  className="grid-gallery__item"
                  key={index}
                >
                  <div className="grid-gallery__overlay">
                    <h2 className="heading">{postContent.title}</h2>
                    <span className="subheading">
                      {postContent.acf.subheading}
                    </span>
                  </div>
                  <div className="image-container">
                    <ImagePass src={postContent?.featured_media} />
                  </div>
                </GatsbyLink>
              )
            );
          })}
      </div>
      <div className="artist-link">
        <GatsbyLink to="/the-artist/" className="button--dark">
          About the artist
        </GatsbyLink>
      </div>
    </section>
  );
};
