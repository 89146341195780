import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { PageHeader } from "../components/PageHeader";
import { ProjectList } from "../components/Projects/ProjectList";
import { decodeEntities } from "../utils/helpers";

const ProjectIndexPage = (props) => {
  const { data, pageContext, location, path } = props;
  const {
    wordpressPage,
    wordpressPost: page = [],
    allWordpressWpProjects,
    categoryFilter,
    categories,
    wordpressCategory,
    wordpressWpSettings,
    siteSettings,
  } = data;
  const { options = null } = siteSettings;
  const { wordpressUrl } = wordpressWpSettings;
  const { title, yoast, acf } = page
    ? page
    : { title: null, yoast: {}, acf: {} };
  const { title: siteTitle } = wordpressWpSettings;
  const { edges: posts } = allWordpressWpProjects;
  const { featureImage: featuredImage } = wordpressPage?.acf;
  const breadcrumbTitle = wordpressPage?.acf?.title;

  return (
    <Fragment>
      <SEO
        title={`${
          yoast.metaTitle
            ? yoast.metaTitle
            : `Projects | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
        yoast={yoast}
        location={location}
      />
      <PageHeader
        headerTitle="Works"
        location={location}
        headerBackgroundImage={featuredImage}
        breadcrumbTitle={breadcrumbTitle}
      />
      <ProjectList
        posts={posts}
        title="Latest projects"
        pageContext={pageContext}
        siteMetadata={wordpressWpSettings}
        categories={categories.edges}
        pathPrefix={"/projects/"}
      />
    </Fragment>
  );
};

export default ProjectIndexPage;

export const pageQuery = graphql`
  query ProjectIndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
    wordpressPage: wordpressPage(path: { eq: "/works/" }) {
      title
      acf {
        title
        featureImage {
          source_url
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 70) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    wordpressPost: wordpressPage(slug: { eq: "projects" }) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
    }
    wordpressCategory: wordpressWpProjectCategory(
      slug: { eq: "uncategorised" }
    ) {
      name
      slug
      path
      description
      acf {
        featureImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 70) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        title
      }
    }
    allWordpressWpProjects(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
        }
      }
    }
  }
`;
